<template>
<div>
   <!-- BANNER -->
  <div class="banner" v-if="loading">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="11" lg="11">
                <div class="d-flex justify-content-center spinner-banner">
                  <b-spinner label="Loading..." variant="primary"></b-spinner>
                </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else class="banner" v-bind:style="{ backgroundImage: 'url(' + `https://panthalasback.flexisoft.usermd.net${services_index.items[0].banner_main_image.url}` + ')' }">
    
    <div class="video-bg">
    
    <div class="overlay"></div>
    
    <b-container fluid class="banner-text">
          <!-- <b-row class="d-flex align-self-left justify-content-left" align-v="left"> -->
            <b-row align-h="end">
              <!-- <b-col cols="1">
              </b-col> -->
              <b-col cols="12" md="11" lg="11">
                <h1>{{ services_index.items[0].title }}</h1>
                <p>{{ services_index.items[0].banner_caption }}</p>
              </b-col>
          </b-row>
    </b-container>

    <b-container fluid class="banner-anchor fadeInAnchor">
          <b-row class="text-center" align-v="center">
              <b-col>
                <b-img src="../assets/anchor.svg" class="anchor" v-scroll-to="'#main-content'"></b-img>
              </b-col>
          </b-row>
    </b-container>
  </div>

</div>
  <!-- END BANNER -->

<!-- SERVICES -->
<b-container fluid class="services-details" id="main-content">
          <b-row align-h="center">
              <b-col cols="12" md="10" lg="8">
                <div class="header-big-left">
                  <div class="header-text">
                    <h2>{{ services_index.items[0].header }}</h2>
                    <h1>{{ services_index.items[0].header_big }}</h1>
                  </div>
                </div>
                <div class="text">
                  <div v-html="services_index.items[0].body"></div>
                </div>
              </b-col>
          </b-row>

          <b-row align-h="center">
              <b-col cols="12" md="10" lg="8" v-if="loadingServices">
                <div class="d-flex justify-content-center mt-5">
                  <b-spinner label="Loading..." variant="primary"></b-spinner>
                </div>
              </b-col>
              <b-col cols="12" md="10" lg="8" v-else>
                  <b-row>
                    <b-col v-for="service in services.items" :key="service.id" cols="12" md="6" class="service-card" @click="openUrl('/commodities/' + service.id + '/' + service.meta.slug )">
                      <b-card
                          overlay
                          :img-src="'https://panthalasback.flexisoft.usermd.net' + service.thumbnail_services.url"
                          :img-alt="service.image.title"
                          text-variant="white"
                        >
                          <b-card-text>
                            {{ service.title }}
                          </b-card-text>
                        </b-card>
                    </b-col>
                  </b-row>
              </b-col>
                    
                    
                  </b-row> 
</b-container>
<!-- END SERVICES -->


</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Services',
  components: {
    // HelloWorld
  },
  data() {
    return {
      services_index: [],
      services: [],
      errors: [],
      loading: true,
      loadingServices: true,
    }
  },
  created () {
    this.getServicesIndex()
    this.getServices()
  },
  methods: {
    getServicesIndex() {
      this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=services.ServicesIndex&fields=*'
      axios({
        method: 'get',
        url: this.url,
        headers: {
          Authorization: 'Token '+ this.mainToken
          }
      }).then(response => {
        this.services_index = response.data
        this.loading = false
      }).catch(e => {
        this.errors.push(e)
      })
    },
    getServices() {
      this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=services.ServicePage&fields=*'
      axios({
        method: 'get',
        url: this.url,
        headers: {
          Authorization: 'Token '+ this.mainToken
          }
      }).then(response => {
        this.services = response.data
        this.loadingServices = false
      }).catch(e => {
        this.errors.push(e)
      })
    },
    openUrl(url) {
      this.$router.push({ path: url })
    },
  },
}
</script>
<style lang="css" scoped>
.banner {
  /* background-image: url(../assets/services-bg.jpg); */
  background-size: cover;
}



/* services */
.services-details {
  margin-top: 70px;
  padding-top: 10px;
  padding-bottom: 30px;
  position: relative;
  height: 100%;
  /* background-color: rgba(28, 28, 30, .9); */
  overflow: hidden;
}
.services-details .text {
  margin-top: 50px;
}

.services {
  position: relative;
  z-index: 999;
}
.service-card:hover {
  cursor: pointer;
  opacity: 0.9;
}
.service-card {
  margin-bottom: 16px;
}


@media (min-width: 600px) and (max-width: 959px) {
  .services-details {
    margin-top: 50px;
  }
  .services-details .text {
    margin-top: 40px;
  }
}
@media (max-width: 600px) {
  
  .services-details {
    margin-top: 50px;
  }
  .services-details .text {
    margin-top: 30px;
  }
}

</style>

