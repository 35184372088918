<template>
<div>
   <!-- BANNER -->
   <div class="banner" v-if="loading">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="11" lg="11">
                <div class="d-flex justify-content-center spinner-banner">
                  <b-spinner label="Loading..." variant="primary"></b-spinner>
                </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else class="banner" v-bind:style="{ backgroundImage: 'url(' + `https://panthalasback.flexisoft.usermd.net${services.items[0].banner_main_image.url}` + ')' }">
    
    <div class="video-bg">
    
    <div class="overlay"></div>
    
    <b-container fluid class="banner-text">
            <b-row align-h="end">
              <b-col cols="12" md="11" lg="11">
                <h1>{{ services.items[0].title }}</h1>
                <div v-html="services.items[0].banner_caption"></div>
              </b-col>
          </b-row>
    </b-container>

    <b-container fluid class="banner-anchor fadeInAnchor">
          <b-row class="text-center" align-v="center">
              <b-col>
                <b-img src="../assets/anchor.svg" class="anchor" v-scroll-to="'#main-content'"></b-img>
              </b-col>
          </b-row>
    </b-container>
   </div>

  </div>
  <!-- END BANNER -->

<!-- ABOUT -->
<b-container fluid class="service" id="main-content">
          <b-row align-h="end">
              <b-col cols="12" md="5" lg="4">
                <div class="header-big-right">
                  <div class="header-text">
                    <h2>{{ services.items[0].header }}</h2>
                    <h1>{{ services.items[0].header_big }}</h1>
                  </div>
                </div>
                <div class="text">
                  <div v-html="services.items[0].body"></div>
                  
                  
                </div>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-img :src="'https://panthalasback.flexisoft.usermd.net' + services.items[0].service_image.url" fluid :alt="services.items[0].image.title"></b-img>
              </b-col>
          </b-row>

          <b-row align-h="end">
              <b-col cols="12" md="11" lg="10">
                  <b-row class="horizontal-images">
                    <b-col cols="4" md="3" lg="2" class="service-thumb" v-for="(image, index) in media" :key="index"  @click="openGallery(index)">
                      <b-img fluid :src="image.thumb" :alt="image.caption"></b-img>
                    </b-col>
                  </b-row>
              </b-col>
              
              
            <LightBox ref="lightbox" :media="media" :show-caption="true" :show-light-box="false"></LightBox>
            
          </b-row>
          <b-row align-h="end">
              <b-col cols="5" md="3" lg="2">
                <b-button variant="primary" @click="openUrl('commodities/')">COMMODITIES</b-button>
              </b-col>
          </b-row>
          

</b-container>
<!-- END ABOUT -->


</div>
</template>

<script>
import axios from 'axios'
import LightBox from 'vue-image-lightbox'
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
export default {
  name: 'Service',
  props: ['id', 'slug'],
  components: {
    LightBox,
  },
  data() {
    return {
      services: [],
      errors: [],
      media: [],
      loading: true,
    }
  },
  created () {
    this.getService()
  },
  methods: {
    getService() {
        this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=services.ServicePage&id=' + this.id + '&fields=*'
        axios({
          method: 'get',
          url: this.url,
          headers: {
            Authorization: 'Token '+ this.mainToken
            }
        }).then(response => {
          this.services = response.data
          this.loading = false
          this.media = this.services.items[0].gallery_images.map(function(i) {
              return {'thumb': 'https://panthalasback.flexisoft.usermd.net' + i.thumbnail.url, 'src': 'https://panthalasback.flexisoft.usermd.net' + i.big_image.url, caption: i.caption}
            })
        }).catch(e => {
          this.errors.push(e)
        })
      },
    openUrl(url) {
      this.$router.push({ path: '/' + url })
    },
     openGallery(index) {
      // this.$refs.lightbox.media=this.media[index]
      this.$refs.lightbox.showImage(index)
    },
  },
  watch: {
    id: function() {
        this.media = []
        this.getService()
      }
  }
}
</script>
<style lang="css" scoped>
.banner {
  /* background-image: url(../assets/yacht-bg.jpg); */
  background-size: cover;
}

/* service */

.service {
  margin-top: 70px;
}
.service .text {
  margin-top: 50px;
}

.horizontal-images {
  padding-top: 40px;
  flex-wrap: nowrap;
  /* max-height: 100px; */
  /* width: 100%; */
  white-space: nowrap;
  overflow-x:auto;
}
.service-thumb {
  display: inline-block;
  float: none;
  margin-bottom: 16px;
}
.service-thumb img:hover {
  cursor: pointer;
}

@media (min-width: 600px) and (max-width: 959px) {
  .service {
    margin-top: 50px;
  }
  .service .text {
    margin-top: 30px;
  }
  .horizontal-images {
    padding-top: 30px;
  }
}
@media (max-width: 600px) {
  .service {
    margin-top: 50px;
  }
  .service .text {
    margin-top: 30px;
  }
  .horizontal-images {
    padding-top: 30px;
  }
}

</style>

