<template>
<div>
  <!-- BANNER -->
  <div class="banner" v-if="loading">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="11" lg="11">
                <div class="d-flex justify-content-center spinner-banner">
                  <b-spinner label="Loading..." variant="primary"></b-spinner>
                </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else class="banner" v-bind:style="{ backgroundImage: 'url(' + `https://panthalasback.flexisoft.usermd.net${contact.items[0].banner_main_image.url}` + ')' }"> 
    
    <div class="video-bg">
    
    <div class="overlay"></div>
    
    <b-container fluid class="banner-text">
            <b-row align-h="end">
              <b-col cols="12" md="11" lg="11">
                <h1>{{ contact.items[0].title }}</h1>
                <p>{{ contact.items[0].banner_caption }}</p>
              </b-col>
          </b-row>
    </b-container>

    <b-container fluid class="banner-anchor fadeInAnchor">
          <b-row class="text-center" align-v="center">
              <b-col>
                <b-img src="../assets/anchor.svg" class="anchor" v-scroll-to="'#main-content'"></b-img>
              </b-col>
          </b-row>
    </b-container>
   </div>

  </div>
  <!-- END BANNER -->

<!-- ABOUT -->
<b-container fluid class="contact" id="main-content">
          <b-row align-h="end">
              <b-col cols="12" md="5" lg="4">
                <div class="header-big-right">
                  <div class="header-text">
                    <h2>Panthalas</h2>
                    <h1>C.</h1>
                  </div>
                </div>
                <div class="text" v-for="contact in contact_data.items" :key="contact.id">
                  <h5>{{ contact.title }}</h5>
                  <ul>
                    <li v-if="contact.phone"><b-img src="../assets/phone-icon.svg"></b-img><b-link :href="`tel:${contact.phone}`">{{ contact.phone }}</b-link></li>
                    <li v-if="contact.mail"><b-img src="../assets/mail-icon.svg"></b-img><b-link :href="`mailto:${contact.mail}`">{{ contact.mail }}</b-link></li>
                    <li v-if="contact.address"><b-img src="../assets/location-icon.svg"></b-img>{{ contact.address }}</li>
                  </ul>
                  <div class="extra-body" v-html="contact.extra_body"></div>
                </div>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-img :src="'https://panthalasback.flexisoft.usermd.net' + contact.items[0].contact_image.url" fluid :alt="contact.items[0].contact_image.alt"></b-img>
              </b-col>
          </b-row>
</b-container>
<!-- END ABOUT -->

</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Contact',
  components: {
    // HelloWorld
  },
  data() {
    return {
      contact: [],
      contact_data: [],
      errors: [],
      loading: true,
    }
  },
  created () {
    this.getContact()
    this.getContactData()
  },
  methods: {
    getContact() {
        this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=contact.ContactIndex&fields=*'
        axios({
          method: 'get',
          url: this.url,
          headers: {
            Authorization: 'Token '+ this.mainToken
            }
        }).then(response => {
          this.contact = response.data
          // this.loading = false
        }).catch(e => {
          this.errors.push(e)
        })
      },
      getContactData() {
        this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=contact.ContactPage&fields=*'
        axios({
          method: 'get',
          url: this.url,
          headers: {
            Authorization: 'Token '+ this.mainToken
            }
        }).then(response => {
          this.contact_data = response.data
          this.loading = false
        }).catch(e => {
          this.errors.push(e)
        })
      },
  }
}
</script>
<style lang="css" scoped>
.banner {
  /* background-image: url(../assets/contact-bg.jpg); */
  background-size: cover;
}


.contact {
  margin-top: 70px;
}
.contact .text {
  margin-top: 50px;
}

.contact .text h5 {
    color: #7F8FA6;
    font-weight: bold;
    padding-bottom: 4px;
}
.contact ul {
  list-style: none;
  padding-left: 0;
}
.contact ul li {
  padding-bottom: 16px;
}
.contact ul li img{
  padding-right: 16px;
  
}
.contact ul li {
  color: #3C6382;
}
.contact ul li a{
  color: #3C6382;
}
.contact ul li a:hover{
  opacity: 0.9;
}
.contact .extra-body {
  color: #3C6382;
}


@media (min-width: 600px) and (max-width: 959px) {
  .contact {
    margin-top: 50px;
  }
  .contact .text {
    margin-top: 40px;
  }
}
@media (max-width: 600px) {
  
  .contact {
    margin-top: 50px;
  }
  .contact .text {
    margin-top: 50px;
  }
}
</style>

