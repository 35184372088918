<template>
<div>
  <!-- BANNER -->
  
  <div class="banner" v-if="loading">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="11" lg="11">
                <div class="d-flex justify-content-center spinner-banner">
                  <b-spinner label="Loading..." variant="primary"></b-spinner>
                </div>
        </b-col>
      </b-row>
    </b-container>
  </div>

  <div class="banner" v-else v-bind:style="{ backgroundImage: 'url(' + `https://panthalasback.flexisoft.usermd.net${about.items[0].banner_main_image.url}` + ')' }"> 
     
    <div class="video-bg">
    
    <div class="overlay"></div>
    
    <b-container fluid class="banner-text">
          <!-- <b-row class="d-flex align-self-left justify-content-left" align-v="left"> -->
            <b-row align-h="end">
              <!-- <b-col cols="1">
              </b-col> -->
              
              <b-col cols="12" md="11" lg="11">
                <h1>{{ about.items[0].title }}</h1>
                <p>{{ about.items[0].banner_caption }}</p>
              </b-col>
          </b-row>
    </b-container>

    <b-container fluid class="banner-anchor fadeInAnchor">
          <b-row class="text-center" align-v="center">
              <b-col>
                <b-img src="../assets/anchor.svg" class="anchor" v-scroll-to="'#main-content'"></b-img>
              </b-col>
          </b-row>
    </b-container>
   </div>

  </div>
  <!-- END BANNER -->

<!-- ABOUT -->
<b-container fluid class="about" id="main-content">
          <b-row align-h="end">
              <b-col cols="12" md="5" lg="4">
                <div class="header-big-right">
                  <div class="header-text">
                    <h2>{{ about.items[0].header }}</h2>
                    <h1>{{ about.items[0].header_big }}</h1>
                  </div>
                </div>
                <div class="text">
                  <div v-html="about.items[0].body"></div>
                  
                </div>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <!-- <b-img v-lazy="'https://localhost:8000' + about.items[0].about_image.url" fluid :alt="about.items[0].about_image.alt"></b-img> -->
                <b-img :src="'https://panthalasback.flexisoft.usermd.net' + about.items[0].about_image.url" fluid :alt="about.items[0].about_image.alt"></b-img>
              </b-col>
          </b-row>
</b-container>
<!-- END ABOUT -->

</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'About',
  components: {
    // HelloWorld
  },
  data() {
    return {
      about: [],
      errors: [],
      loading: true,
    }
  },
  created () {
    this.getAbout()
  },
  methods: {
    getAbout() {
      // this.url = 'http://localhost:8000/api/v2/pages/?type=about.AboutPage&fields=*'
      this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=about.AboutPage&fields=*'
      axios({
        method: 'get',
        url: this.url,
        headers: {
          Authorization: 'Token '+ this.mainToken
          }
      }).then(response => {
        this.about = response.data
        this.loading = false
      }).catch(e => {
        this.errors.push(e)
      })
    },
  }
}
</script>
<style lang="css" scoped>
.banner {
  /* background-image: url(../assets/about-bg.jpg); */
  background-size: cover;
}
</style>

